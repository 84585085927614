function getValueFacet(aggregations, fieldName) {
  if (
    aggregations &&
    aggregations[fieldName] &&
    aggregations[fieldName].buckets &&
    aggregations[fieldName].buckets.length > 0
  ) {
    return [
      {
        field: fieldName,
        type: "value",
        data: aggregations[fieldName].buckets.map(bucket => ({
          // Boolean values and date values require using `key_as_string`
          value: bucket.key_as_string || bucket.key,
          count: bucket.doc_count
        }))
      }
    ];
  }
}

export default function buildStateFacets(aggregations,my_conf_aggs) {

  const my_conf_aggs_value= [];
  if (my_conf_aggs) {
    for (let i = 0; i < my_conf_aggs.length; i++) {
      my_conf_aggs_value[i] = getValueFacet(aggregations, my_conf_aggs[i]);
      
    }
  };
  
  var facets = {};
  if (my_conf_aggs) {
    for (let i = 0; i < my_conf_aggs.length; i++) {
      facets[my_conf_aggs[i]] = my_conf_aggs_value[i];
      
    }
  };

  if (Object.keys(facets).length > 0) {
    return facets;
  }
}


import React from "react";

function testSnippetRaw(snip,raw) { //JW
  return snip ? snip : raw ;
}
  
export default ({ result }) => (
  
  <li className="sui-result">
    <div className="sui-result__body">
      <ul className="sui-result__details">        
        <li>
            <dl className="dreiklang dreiklang_dl"
              dangerouslySetInnerHTML={{
                __html: testSnippetRaw(result.proxy_citation.snippet,result.proxy_citation.raw)
              }}
            />      
        </li>
     </ul>
    </div>
  </li>
);
